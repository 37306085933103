import React, { useState } from 'react';
import { FaCalendarAlt, FaCheckCircle, FaTimesCircle, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Carousel from '../components/Carousel';

const Home = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const topCarouselItems = [
    <img src="https://placehold.co/800x400/3498db/ffffff?text=Slide+1" alt="Slide 1" className="w-full h-[400px] object-cover" />,
    <img src="https://placehold.co/800x400/e74c3c/ffffff?text=Slide+2" alt="Slide 2" className="w-full h-[400px] object-cover" />,
    <img src="https://placehold.co/800x400/2ecc71/ffffff?text=Slide+3" alt="Slide 3" className="w-full h-[400px] object-cover" />,
  ];
  const courses = [
    {
      title: "Python Temel Kurs",
      description: "Python programlama dilinin temellerini öğrenin.",
      image: "https://placehold.co/400x200/3498db/ffffff?text=Python+Temel+Kurs",
      nextLesson: "Değişkenler ve Veri Tipleri",
      nextLessonDate: "05.11.2023 19:00",
      assignments: [
        { name: "Python Kurulumu", dueDate: "01.11.2023 19:00", completed: true },
        { name: "İlk Python Programı", dueDate: "03.11.2023 19:00", completed: false },
        { name: "Veri Tipleri Alıştırması", dueDate: "07.11.2023 19:00", completed: false },
      ],
      projects: [
        { name: "Hesap Makinesi", startDate: "01.11.2023", endDate: "15.11.2023" },
        { name: "Basit Oyun", startDate: "16.11.2023", endDate: "30.11.2023" },
      ]
    },
    {
      title: "Web Geliştirme",
      description: "HTML, CSS ve JavaScript ile web geliştirmeyi öğrenin.",
      image: "https://placehold.co/400x200/e74c3c/ffffff?text=Web+Geliştirme",
      nextLesson: "CSS Flexbox ve Grid",
      nextLessonDate: "07.11.2023 19:00",
      assignments: [
        { name: "HTML Yapısı", dueDate: "02.11.2023 19:00", completed: true },
        { name: "CSS Seçiciler", dueDate: "04.11.2023 19:00", completed: false },
        { name: "Responsive Tasarım", dueDate: "08.11.2023 19:00", completed: false },
      ],
      projects: [
        { name: "Kişisel Web Sitesi", startDate: "05.11.2023", endDate: "20.11.2023" },
        { name: "Hava Durumu Uygulaması", startDate: "21.11.2023", endDate: "05.12.2023" },
      ]
    },
    {
      title: "Veri Bilimi",
      description: "Veri analizi ve makine öğrenimi temellerini keşfedin.",
      image: "https://placehold.co/400x200/2ecc71/ffffff?text=Veri+Bilimi",
      nextLesson: "Pandas ile Veri Manipülasyonu",
      nextLessonDate: "09.11.2023 19:00",
      assignments: [
        { name: "NumPy Temelleri", dueDate: "06.11.2023 19:00", completed: true },
        { name: "Pandas Serileri", dueDate: "08.11.2023 19:00", completed: false },
        { name: "Veri Görselleştirme", dueDate: "10.11.2023 19:00", completed: false },
      ],
      projects: [
        { name: "Veri Analizi Raporu", startDate: "10.11.2023", endDate: "25.11.2023" },
        { name: "Makine Öğrenimi Modeli", startDate: "26.11.2023", endDate: "10.12.2023" },
      ]
    },
  ];

  const nextSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % courses.length);
  };

  const prevSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + courses.length) % courses.length);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <section className="mb-12">
        <Carousel items={topCarouselItems} autoChange={true} interval={5000} />
      </section>

      <section className="mb-12">
        <h2 className="text-3xl font-bold mb-6">Kurslarım</h2>
        <div className="card bg-base-100 shadow-xl">
          <div className="card-body">
            <div className="relative">
              <button onClick={prevSlide} className="btn btn-circle btn-sm absolute left-0 top-1/2 transform -translate-y-1/2 z-10">
                <FaChevronLeft />
              </button>
              <button onClick={nextSlide} className="btn btn-circle btn-sm absolute right-0 top-1/2 transform -translate-y-1/2 z-10">
                <FaChevronRight />
              </button>
              <div className="carousel w-full">
                {courses.map((course, index) => (
                  <div key={index} className={`carousel-item relative w-full ${index === activeIndex ? '' : 'hidden'}`}>
                    <img src={course.image} alt={course.title} className="w-full h-64 object-cover rounded-xl" />
                    <div className="absolute inset-0 bg-black bg-opacity-60 flex items-center justify-center">
                      <div className="text-center text-white">
                        <h3 className="text-2xl font-bold mb-2">{course.title}</h3>
                        <p>{course.description}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="mt-6">
              <h3 className="text-xl font-semibold mb-2">Sonraki Ders</h3>
              <div className="bg-base-200 p-4 rounded-lg">
                <p className="font-medium">{courses[activeIndex].nextLesson}</p>
                <p className="text-sm text-gray-500 mt-1">
                  <FaCalendarAlt className="inline mr-2" />
                  {courses[activeIndex].nextLessonDate}
                </p>
              </div>
            </div>

            <div className="mt-6">
              <h3 className="text-xl font-semibold mb-2">Ödevler</h3>
              <ul className="space-y-2">
                {courses[activeIndex].assignments.map((assignment, index) => (
                  <li key={index} className="bg-base-200 p-3 rounded-lg flex items-center justify-between">
                    <a 
                      href={`/assignment?course=${encodeURIComponent(courses[activeIndex].title)}&assignment=${encodeURIComponent(assignment.name)}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:underline"
                    >
                      {assignment.name}
                    </a>
                    <div className="flex items-center">
                      <span className="text-sm text-gray-500 mr-2">{assignment.dueDate}</span>
                      {assignment.completed ? (
                        <FaCheckCircle className="text-success" />
                      ) : (
                        <FaTimesCircle className="text-error" />
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <div className="mt-6">
              <h3 className="text-xl font-semibold mb-2">Projeler</h3>
              <ul className="space-y-2">
                {courses[activeIndex].projects.map((project, index) => (
                  <li key={index} className="bg-base-200 p-3 rounded-lg flex items-center justify-between">
                    <span>{project.name}</span>
                    <div className="text-sm text-gray-500">
                      <span>{project.startDate}</span>
                      <span className="mx-2">-</span>
                      <span>{project.endDate}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;