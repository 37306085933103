import { createStore, combineReducers } from 'redux';

// Örnek bir reducer
const assignmentReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SET_ASSIGNMENT':
      return { ...state, currentAssignment: action.payload };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  assignment: assignmentReducer,
});

const store = createStore(rootReducer);

export default store;