import React, { useState, useEffect, useRef } from 'react';
import Editor from '@monaco-editor/react';
import { FaPlay, FaUndo } from 'react-icons/fa';

const LessonPage = () => {
  const [code, setCode] = useState(`
from browser import document, window
import turtle
import sys

# Redirect standard output
class OutputCatcher:
    def __init__(self):
        self.content = ""
    def write(self, string):
        self.content += string
    def flush(self):
        pass

sys.stdout = OutputCatcher()

# Turtle canvas'ını ayarla
turtle_div = document['turtle-div']
turtle.set_defaults(
    turtle_canvas_wrapper = turtle_div
)

# Canvas boyutunu ayarla
turtle.setup(400, 300)  # genişlik: 400px, yükseklik: 300px

# Yeni bir Turtle oluştur
t = turtle.Turtle()

t.width(5)

for c in ['red', '#00ff00', '#fa0', 'rgb(0,0,200)']:
    t.color(c)
    t.forward(100)
    t.left(90)
    print(f"Çizilen renk: {c}")

# dot() ve write() için kalemin aşağıda olması gerekmez
t.penup()
t.goto(-30, -100)
t.dot(40, 'rgba(255, 0, 0, 0.5)')
t.goto(30, -100)
t.dot(40, 'rgba(0, 255, 0, 0.5)')
t.goto(0, -70)
t.dot(40, 'rgba(0, 0, 255, 0.5)')

t.goto(0, 125)
t.color('purple')
t.write("I love Brython!", font=("Arial", 20, "normal"))

turtle.done()

# Çıktıyı al
output = sys.stdout.content
window.updateOutput(output)  # Directly update React state
  `);
  const [output, setOutput] = useState('');
  const [activeTab, setActiveTab] = useState('turtle');
  const turtleDivRef = useRef(null);

  useEffect(() => {
    // Define a global function to update the output state from Python
    window.updateOutput = (text) => {
      setOutput(text);
    };

    if (!window.brython) {
      const loadBrython = () => {
        const script1 = document.createElement('script');
        script1.src = 'https://cdn.jsdelivr.net/npm/brython@3/brython.min.js';
        script1.async = true;
        script1.onload = () => {
          const script2 = document.createElement('script');
          script2.src = 'https://cdn.jsdelivr.net/npm/brython@3/brython_stdlib.js';
          script2.async = true;
          script2.onload = () => {
            window.brython({ debug: 1 });
          };
          document.body.appendChild(script2);
        };
        document.body.appendChild(script1);
      };

      loadBrython();
    } else {
      window.brython({ debug: 1 });
    }

    // Cleanup function to remove the global function when component unmounts
    return () => {
      delete window.updateOutput;
    };
  }, []);

  const runCode = () => {
    if (turtleDivRef.current) {
      turtleDivRef.current.innerHTML = ''; // Clear previous turtle drawings
    }
    setOutput(''); // Clear previous output

    try {
      window.__BRYTHON__.runPythonSource(code);
      // No need to setOutput here; it will be updated by window.updateOutput
    } catch (error) {
      console.error('Python code execution error:', error);
      setOutput(`Hata: ${error}`);
    }
  };

  const clearCanvas = () => {
    if (turtleDivRef.current) {
      turtleDivRef.current.innerHTML = '';
    }
    setOutput('');
  };

  return (
    <div className="flex h-screen bg-base-200">
      {/* Sol taraf - Eğitim Dokümanı */}
      <div className="w-1/3 p-4 overflow-y-auto bg-base-100">
        <h2 className="text-2xl font-bold mb-4">Python Turtle Eğitimi</h2>
        <p className="mb-4">
          Bu eğitimde, Python'un Turtle modülünü kullanarak basit çizimler yapmayı öğreneceksiniz.
        </p>
        <h3 className="text-xl font-semibold mb-2">Temel Komutlar:</h3>
        <ul className="list-disc list-inside mb-4">
          <li>forward(x): İleri x birim git</li>
          <li>backward(x): Geri x birim git</li>
          <li>right(x): Sağa x derece dön</li>
          <li>left(x): Sola x derece dön</li>
          <li>penup(): Kalemi kaldır</li>
          <li>pendown(): Kalemi indir</li>
        </ul>
        <p>
          Sağdaki editörde kodu yazın ve "Çalıştır" butonuna basarak sonucu görün.
        </p>
      </div>

      {/* Sağ taraf - Code Editor ve Çıktı */}
      <div className="w-2/3 flex flex-col p-4">
        <div className="card bg-base-100 shadow-xl mb-4 flex-grow">
          <div className="card-body">
            <h2 className="card-title">Kod Editörü</h2>
            <Editor
              height="50vh"
              defaultLanguage="python"
              theme="vs-dark"
              value={code}
              onChange={(value) => setCode(value || '')}
              options={{
                minimap: { enabled: false },
                fontSize: 14,
              }}
            />
            <div className="card-actions justify-end mt-4">
              <button className="btn btn-primary" onClick={runCode}>
                <FaPlay className="mr-2" /> Çalıştır
              </button>
              <button className="btn btn-secondary" onClick={clearCanvas}>
                <FaUndo className="mr-2" /> Temizle
              </button>
            </div>
          </div>
        </div>

        {/* Çıktı Ekranı */}
        <div className="card bg-base-100 shadow-xl">
          <div className="card-body">
            <h2 className="card-title">Çıktı</h2>
            <div className="tabs tabs-boxed mb-4">
              <a
                className={`tab ${activeTab === 'turtle' ? 'tab-active' : ''}`}
                onClick={() => setActiveTab('turtle')}
              >
                Turtle Çizimi
              </a>
              <a
                className={`tab ${activeTab === 'console' ? 'tab-active' : ''}`}
                onClick={() => setActiveTab('console')}
              >
                Konsol Çıktısı
              </a>
            </div>
            {activeTab === 'turtle' ? (
              <div
                id="turtle-div"
                ref={turtleDivRef}
                className="w-full h-[300px] bg-white border rounded-box overflow-hidden"
              ></div>
            ) : (
              <pre className="bg-base-200 p-2 rounded-box h-[300px] overflow-auto whitespace-pre-wrap">
                {output || 'Kod başarıyla çalıştırıldı!'}
              </pre>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LessonPage;
