import React, { useState } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import AppRoutes from './components/AppRoutes';

const AppContent = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();

  const isAssignmentPage = location.pathname.startsWith('/assignment');

  if (isAssignmentPage) {
    return (
      <main className="h-screen overflow-x-hidden overflow-y-auto bg-gray-200 p-6">
        <AppRoutes />
      </main>
    );
  }

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar isOpen={sidebarOpen} setIsOpen={setSidebarOpen} />
      <div className="flex-1 flex flex-col overflow-hidden">
        <Header toggleSidebar={() => setSidebarOpen(!sidebarOpen)} />
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200 p-6">
          <AppRoutes />
        </main>
      </div>
    </div>
  );
};

function App() {
  return (
    <Provider store={store}>
      <Router>
        <AppContent />
      </Router>
    </Provider>
  );
}

export default App;