import React, { useState } from 'react';
import { FiInfo, FiChevronLeft, FiChevronRight, FiStar, FiLock, FiChevronDown } from 'react-icons/fi';
import InClassTasksTab from '../components/InClassTasksTab';
import AssignmentsTab from '../components/AssignmentsTab';
import LeaderboardTab from '../components/LeaderboardTab';

const CoursePage = () => {
  const [activeTab, setActiveTab] = useState('tasks');

  const modules = [
    { id: 1, name: 'Modül 1', progress: '4/4', total: '252/468', color: 'bg-blue-500' },
    { id: 2, name: 'Modül 2', progress: '4/4', total: '309/640', color: 'bg-blue-400' },
    { id: 3, name: 'Modül 3', progress: '4/4', total: '506/806', color: 'bg-blue-300' },
  ];

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="bg-white rounded-2xl shadow-lg p-6 mb-8">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
          <div className="flex items-center mb-4 md:mb-0">
            <span className="bg-red-500 text-white text-xs font-semibold px-3 py-1 rounded-full mr-2">DURAKLAMADA</span>
            <FiInfo className="text-gray-400" />
          </div>
          <div className="text-gray-600 font-medium">
            <span className="text-2xl font-bold text-gray-800">2990</span> / 6188
          </div>
        </div>
        <h1 className="text-4xl font-bold mb-4 text-gray-900">Python Basic</h1>
        <p className="text-gray-600 mb-6">Derslerin 32 tanesinden 32 tanesi tamamlandı.</p>
        <div className="w-full bg-gray-200 rounded-full h-2 mb-6">
          <div className="bg-blue-600 h-2 rounded-full" style={{width: '100%'}}></div>
        </div>
        <div className="flex justify-end">
          <img src="https://placehold.co/100x100?text=Python" alt="Python logo" className="w-24 h-24 rounded-lg shadow-md" />
        </div>
      </div>

      <div className="mb-8">
        <nav className="flex space-x-4 border-b border-gray-200">
          {['tasks', 'assignments', 'leaderboard'].map((tab) => (
            <button
              key={tab}
              className={`py-4 px-1 font-medium text-sm focus:outline-none ${
                activeTab === tab
                  ? 'border-b-2 border-blue-500 text-blue-600'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
              onClick={() => setActiveTab(tab)}
            >
              {tab === 'tasks' && 'Ders İçi Görevler'}
              {tab === 'assignments' && (
                <span className="flex items-center">
                  Ödevler
                  <span className="ml-2 bg-pink-500 text-white text-xs font-semibold px-2 py-1 rounded-full">10</span>
                </span>
              )}
              {tab === 'leaderboard' && (
                <span className="flex items-center">
                  Lider Tablosu <FiStar className="ml-1 text-yellow-400" />
                </span>
              )}
            </button>
          ))}
        </nav>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
        {modules.map((module) => (
          <div key={module.id} className={`${module.color} text-white p-6 rounded-xl shadow-md`}>
            <h3 className="text-xl font-semibold mb-2">{module.name}</h3>
            <p className="mb-2">Derslerin {module.progress} tanesi tamamlandı.</p>
            <div className="text-sm font-medium">{module.total}</div>
          </div>
        ))}
      </div>

      {activeTab === 'tasks' && <InClassTasksTab />}
      {activeTab === 'assignments' && <AssignmentsTab />}
      {activeTab === 'leaderboard' && <LeaderboardTab />}

      <div className="flex justify-between items-center mt-8">
        <button className="flex items-center text-blue-600 hover:text-blue-800 transition-colors duration-200">
          <FiLock className="mr-2" /> Ders kapalı
        </button>
        <div className="flex space-x-4">
          <button className="p-2 bg-gray-100 rounded-full hover:bg-gray-200 transition-colors duration-200">
            <FiChevronLeft className="text-gray-600" />
          </button>
          <button className="p-2 bg-gray-100 rounded-full hover:bg-gray-200 transition-colors duration-200">
            <FiChevronRight className="text-gray-600" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CoursePage;