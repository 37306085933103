import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaHome, FaBook, FaRocket, FaTrophy, FaCog, FaWhatsapp, FaSun, FaMoon } from 'react-icons/fa';

const Sidebar = ({ isOpen, setIsOpen }) => {
  const location = useLocation();

  const menuItems = [
    { icon: FaHome, text: 'Ana Sayfa', path: '/' },
    { icon: FaBook, text: 'Kurslarım', path: '/courses' },
    { icon: FaRocket, text: 'HUB', path: '/hub' },
    { icon: FaTrophy, text: 'Başarımlarım', path: '/achievements' },
    { icon: FaCog, text: 'Ayarlar', path: '/settings' },
  ];

  return (
    <div className={`fixed inset-y-0 left-0 z-50 w-64 bg-blue-50 transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out lg:translate-x-0 lg:static lg:inset-0 flex flex-col`}>
      <div className="flex items-center justify-center h-16 border-b border-blue-100">
        <h1 className="text-2xl font-bold text-gray-800">codeland</h1>
      </div>
      <nav className="flex-grow py-6">
        {menuItems.map((item, index) => (
          <Link
            key={index}
            to={item.path}
            className={`flex items-center px-6 py-3 text-sm font-medium transition-colors duration-200 ${
              location.pathname === item.path
                ? 'bg-blue-100 text-blue-600'
                : 'text-gray-600 hover:bg-blue-100 hover:text-blue-600'
            }`}
          >
            <item.icon className="mr-3" size={18} />
            {item.text}
          </Link>
        ))}
      </nav>
      <div className="p-4 mt-auto">
        <div className="bg-white rounded-lg shadow-md p-4">
          <div className="flex items-center mb-2">
            <FaWhatsapp className="text-green-500 mr-2" size={24} />
            <span className="font-medium text-gray-700">Kurs Grup Sohbeti</span>
          </div>
          <p className="text-sm text-gray-500 mb-2">Burada birbirinizle sohbet edebilirsiniz</p>
          <button className="w-full bg-pink-500 text-white py-2 rounded-md hover:bg-pink-600 transition-colors duration-200">
            Katıl
          </button>
        </div>
      </div>
      <div className="flex justify-center p-4 border-t border-blue-100">
        <button className="text-gray-500 hover:text-gray-700 mr-4">
          <FaSun size={20} />
        </button>
        <button className="text-gray-500 hover:text-gray-700">
          <FaMoon size={20} />
        </button>
      </div>
    </div>
  );
};

export default Sidebar;