import React, { useState } from 'react';
import { FaStar, FaChevronDown } from 'react-icons/fa';

const AssignmentCard = ({ module, lesson, title, progress, total, dueDate, status }) => (
  <div className="bg-blue-50 rounded-lg p-4 flex">
    <div className="w-1/4 mr-4">
      <div className="w-20 h-20 bg-gradient-to-br from-pink-400 to-purple-500 rounded-lg flex items-center justify-center">
        {/* Placeholder for the colorful icon */}
        <span className="text-white text-4xl">+</span>
      </div>
    </div>
    <div className="w-3/4">
      <div className="text-sm text-blue-600 mb-1">{`Modül ${module}, Ders ${lesson}`}</div>
      <h3 className="text-lg font-semibold mb-2">{title}</h3>
      <div className="flex justify-between items-center mb-2">
        <span className="text-sm font-medium">{`${progress}/${total}`}</span>
        <span className="text-sm text-gray-500">{`Son tarih ${dueDate}`}</span>
      </div>
      <div className="w-full bg-gray-200 rounded-full h-2.5">
        <div 
          className="bg-pink-500 h-2.5 rounded-full" 
          style={{ width: `${(progress / total) * 100}%` }}
        ></div>
      </div>
      <div className="mt-2 text-right">
        <span className={`text-xs font-semibold px-2 py-1 rounded ${
          status === 'TAMAMLANDI' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
        }`}>
          {status}
        </span>
      </div>
    </div>
  </div>
);

const AssignmentsTab = () => {
  const [filter, setFilter] = useState('Tümü');

  const assignments = [
    { module: 1, lesson: 1, title: 'M1.L1 Turtle', progress: 3, total: 4, dueDate: '01.09.2023 19:00', status: 'TAMAMLANMADI' },
    { module: 1, lesson: 2, title: 'M1.L2 Değişkenler, Veri Türleri ve Veri Türleri ile İşlemler', progress: 4, total: 4, dueDate: '08.09.2023 19:00', status: 'TAMAMLANDI' },
    { module: 1, lesson: 3, title: 'M1.L3 Yazı ve Sayı Girmek', progress: 2, total: 3, dueDate: '15.09.2023 19:00', status: 'TAMAMLANMADI' },
    { module: 2, lesson: 1, title: 'M2.L1 Koşullu İfadeler', progress: 0, total: 3, dueDate: '29.09.2023 19:00', status: 'TAMAMLANMADI' },
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      {/* Filtreleme ve arama kısmı */}
      <div className="mb-6">
        <div className="relative inline-block text-left">
          <div>
            <button 
              type="button" 
              className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500" 
              id="options-menu" 
              aria-haspopup="true" 
              aria-expanded="true"
              onClick={() => setFilter(filter === 'Tümü' ? 'Tamamlananlar' : 'Tümü')} // Basit bir toggle örneği
            >
              {filter}
              <FaChevronDown className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>

      {/* Ödev Kartları */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {assignments
          .filter(assignment => filter === 'Tümü' || (filter === 'Tamamlananlar' ? assignment.status === 'TAMAMLANDI' : true))
          .map((assignment, index) => (
            <AssignmentCard key={index} {...assignment} />
        ))}
      </div>
    </div>
  );
};

export default AssignmentsTab;
