import React from 'react';
import { FaTrophy, FaMedal } from 'react-icons/fa';

const LeaderboardTab = () => {
  const leaderboardData = [
    { rank: 1, name: 'Ahmet Yılmaz', score: 980 },
    { rank: 2, name: 'Ayşe Demir', score: 945 },
    { rank: 3, name: 'Mehmet Kaya', score: 920 },
    { rank: 4, name: 'Fatma Şahin', score: 890 },
    { rank: 5, name: 'Ali Öztürk', score: 875 },
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-2xl font-bold mb-6">Lider Tablosu</h2>
      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <table className="w-full">
          <thead>
            <tr className="bg-gray-100">
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Sıra</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">İsim</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Puan</th>
            </tr>
          </thead>
          <tbody>
            {leaderboardData.map((user, index) => (
              <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                <td className="px-6 py-4 whitespace-nowrap">
                  {user.rank === 1 && <FaTrophy className="text-yellow-400 inline mr-2" />}
                  {user.rank === 2 && <FaMedal className="text-gray-400 inline mr-2" />}
                  {user.rank === 3 && <FaMedal className="text-yellow-700 inline mr-2" />}
                  {user.rank}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">{user.name}</td>
                <td className="px-6 py-4 whitespace-nowrap">{user.score}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LeaderboardTab;