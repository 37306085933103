import React from 'react';
import { FaBell, FaInfoCircle, FaGlobe, FaChevronDown } from 'react-icons/fa';

const Header = () => {
  return (
    <header className="bg-white shadow-sm">
      <div className="container mx-auto px-4 py-2 flex items-center justify-between">
        <div className="flex-1">
          <button className="bg-pink-500 text-white px-4 py-2 rounded-full flex items-center hover:bg-pink-600 transition-colors duration-200">
            <span className="mr-2">⚠️</span>
            Tamamlanmamış ödeviniz var
            <span className="ml-2">Git →</span>
          </button>
        </div>
        <div className="flex items-center space-x-4">
          <div className="dropdown dropdown-end">
            <label tabIndex={0} className="bg-gray-100 px-3 py-1 rounded-full flex items-center cursor-pointer">
              <span className="text-gray-600 mr-2">Bakiye</span>
              <span className="font-semibold">6 ders</span>
              <FaChevronDown className="ml-2 text-gray-500" />
            </label>
            <div tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52 z-[100]">
              <div className="p-2">
                <p className="text-sm text-gray-600">Mevcut Bakiye</p>
                <p className="font-semibold">6 ders</p>
                <button className="btn btn-primary btn-sm mt-2 w-full">Bakiye Yükle</button>
              </div>
            </div>
          </div>
          
          <div className="dropdown dropdown-end">
            <label tabIndex={0} className="bg-gray-100 px-3 py-1 rounded-full cursor-pointer">
              <FaGlobe className="inline mr-1" />
              <span>TR</span>
              <FaChevronDown className="inline ml-1 text-gray-500" />
            </label>
            <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52 z-[100]">
              <li><a>Türkçe</a></li>
              <li><a>English</a></li>
            </ul>
          </div>
          
          <div className="dropdown dropdown-end">
            <label tabIndex={0} className="btn btn-ghost btn-circle">
              <FaInfoCircle size={20} />
            </label>
            <div tabIndex={0} className="dropdown-content z-[100] menu p-2 shadow bg-base-100 rounded-box w-52">
              <p className="p-2 text-sm">Yardım ve destek için buradayız.</p>
            </div>
          </div>
          
          <div className="dropdown dropdown-end">
            <label tabIndex={0} className="btn btn-ghost btn-circle">
              <div className="indicator">
                <FaBell size={20} />
                <span className="badge badge-sm indicator-item bg-red-500 border-none"></span>
              </div>
            </label>
            <ul tabIndex={0} className="dropdown-content z-[100] menu p-2 shadow bg-base-100 rounded-box w-52">
              <li><a>Yeni bildirim</a></li>
              <li><a>Tüm bildirimleri gör</a></li>
            </ul>
          </div>
          
          <div className="dropdown dropdown-end">
            <label tabIndex={0} className="btn btn-ghost btn-circle avatar online">
              <div className="w-10 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                <img src="https://placehold.co/100x100" alt="User Avatar" />
              </div>
            </label>
            <ul tabIndex={0} className="mt-3 z-[100] p-2 shadow menu menu-sm dropdown-content bg-base-100 rounded-box w-52">
              <li><a>Profil</a></li>
              <li><a>Ayarlar</a></li>
              <li><a>Çıkış</a></li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;