import React, { useState } from 'react';
import { FiChevronDown, FiChevronUp, FiBook, FiEdit, FiStar } from 'react-icons/fi';

const InClassTasksTab = () => {
  const [isLessonOpen, setIsLessonOpen] = useState(false);

  const lesson = {
    id: 1,
    name: '1. MLI: Turtle',
    status: 'Tamamlanmadı',
    score: '59/72',
    tasks: [
      { name: 'Giriş ve Tanıtım', status: 'Kontrol edildi', score: '8/8' },
      { name: 'Koordinatları Hareket Ettirme', status: 'Kontrol edildi', score: '8/8' },
      { name: 'Kare', status: 'Kontrol edildi', score: '8/8' },
      { name: 'Renkli Kaplumbağa', status: 'Kontrol edildi', score: '8/8' },
      { name: 'İç Bükey/Dışbükey İkizkenar', status: 'Kontrol edildi', score: '8/8' },
      { name: 'Renkli Dörtgenler', status: 'Kontrol edildi', score: '8/8' },
      { name: 'Kahoot', status: 'Tamamlanmadı', score: '0/8' },
      { name: 'Labirent (Ek Alıştırma)', status: 'Kontrol edildi', score: '3/8', isExtra: true },
      { name: 'Renkli Kareler', status: 'Kontrol edildi', score: '8/8', isExtra: true },
      { name: 'Altı Tane Açı (Ek Alıştırma)', status: 'Tamamlanmadı', score: '0/', isExtra: true },
    ]
  };

  return (
    <div className="bg-white rounded-xl shadow-md overflow-hidden">
      <div 
        className="flex items-center justify-between p-6 cursor-pointer hover:bg-gray-50 transition-colors duration-200"
        onClick={() => setIsLessonOpen(!isLessonOpen)}
      >
        <h3 className="text-xl font-semibold text-gray-800">{lesson.name}</h3>
        <div className="flex items-center">
          <span className={`mr-4 ${lesson.status === 'Tamamlanmadı' ? 'text-red-500' : 'text-green-500'} font-medium`}>
            {lesson.status}
          </span>
          <span className="text-blue-600 font-medium mr-4">{lesson.score}</span>
          {isLessonOpen ? <FiChevronUp className="text-gray-400" /> : <FiChevronDown className="text-gray-400" />}
        </div>
      </div>
      {isLessonOpen && (
        <div className="px-6 pb-6">
          <div className="flex justify-between items-center mb-4">
            <h4 className="font-semibold text-gray-700">Ders İçi Görevler</h4>
            <div className="flex space-x-4">
              <button className="flex items-center text-blue-600 hover:text-blue-800 transition-colors duration-200 text-sm">
                <FiBook className="mr-2" /> Ek materyaller
              </button>
              <button className="flex items-center text-blue-600 hover:text-blue-800 transition-colors duration-200 text-sm">
                <FiEdit className="mr-2" /> Ödevler
              </button>
            </div>
          </div>
          {lesson.tasks.map((task, index) => (
            <div key={index} className="flex items-center justify-between py-3 border-b border-gray-200 last:border-b-0">
              <div className="flex items-center">
                {task.isExtra && <FiStar className="text-yellow-400 mr-2" />}
                <span className="text-gray-700">{task.name}</span>
              </div>
              <div className="flex items-center">
                <span className={`mr-4 ${task.status === 'Tamamlanmadı' ? 'text-red-500' : 'text-green-500'} text-sm`}>
                  {task.status}
                </span>
                <span className="text-blue-600 text-sm">{task.score}</span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default InClassTasksTab;