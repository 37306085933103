import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/Home';
import CoursePage from '../pages/CoursePage';
import Hub from '../pages/Hub';
import Achievements from '../pages/Achievements';
import Settings from '../pages/Settings';
import LessonPage from '../pages/LessonPage';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/courses" element={<CoursePage />} />
      <Route path="/hub" element={<Hub />} />
      <Route path="/achievements" element={<LessonPage />} />
      <Route path="/settings" element={<Settings />} />
    </Routes>
  );
};

export default AppRoutes;