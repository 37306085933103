import React from 'react';

const Hub = () => {
  return (
    <div className="card bg-base-100 shadow-xl">
      <div className="card-body">
        <h2 className="card-title">Hub</h2>
        <p>Burası Hub sayfasıdır.</p>
      </div>
    </div>
  );
};

export default Hub;